import * as Msal from "msal";

const username = document.querySelector('.username');
const progress = document.querySelector('.progress');
const record = document.querySelector('.record');
const stop = document.querySelector('.stop');

const config = {
    auth: {
      clientId: "7546061c-c729-4195-af95-1a2384e77f86",
      authority: "https://login.microsoftonline.com/db26bbbc-5194-4d9f-8de2-378b0adb684a",
      //redirectUri: "http://localhost:1234",
      redirectUri: "https://rec.minutesservices.com",
      postLogoutRedirectUri: "https://login.microsoftonline.com/common/oauth2/logout",
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
      },
  };
  
  const msal = new Msal.UserAgentApplication(config);
  const useraccount = msal.getAllAccounts()[0];

  var loginRequest = {
    authority: "https://login.microsoftonline.com/db26bbbc-5194-4d9f-8de2-378b0adb684a",    
    scopes: ["User.Read", "profile"],
  };
  
  if(useraccount){
    loginRequest = {
      authority: "https://login.microsoftonline.com/db26bbbc-5194-4d9f-8de2-378b0adb684a",      
      scopes: ["User.Read", "profile"],
      account: useraccount
    };
  }

  progress.innerHTML = "<p>Loading app, please wait ...</p>";
  record.disabled = true;

  msal.loginPopup(loginRequest).then(function (idToken) {
    //Login Success
    msal.acquireTokenSilent(loginRequest).then(function (accessToken) {
        //AcquireToken Success
        username.innerText = accessToken.idTokenClaims.name;
        progress.innerHTML = `<p>Hi ${username.innerText}, click Record to start recording.</p>`;
        record.disabled = false;
    }, function (error) {
        //AcquireToken Failure, send an interactive request.
        msal.acquireTokenPopup(loginRequest).then(function (accessToken) {
          username.innerText = accessToken.idTokenClaims.name;
          progress.innerHTML = `<p>Hi ${username.innerText}, click Record to start recording.</p>`;
          record.disabled = false;
        }, function (error) {
            console.log(error);
            progress.innerHTML = error;
        });
    })
  }, function (error) {
      console.log(error);
      progress.innerHTML = error;
  });

  /*
  msal
  .acquireTokenSilent(loginRequest)
  .then(function (accessToken) {
    // Acquire token silent success
    username.innerText = accessToken.idTokenClaims.name;
    progress.innerHTML = `<p>Hi ${username.innerText}, click Record to start recording.</p>`;
    record.disabled = false;
  })
  .catch(function (error) {
    console.log(error);
    //Acquire token silent failure, and send an interactive request
    //msal.acquireTokenRedirect(loginRequest)
    msal.loginRedirect(loginRequest)
    .catch((e) => {
      const err = JSON.stringify(e);
      progress.innerHTML = `<p class="messageError">Login failed! Please <a href=" ${msal.getPostLogoutRedirectUri()} ">logout</a> and log back in.</p>`;
    });
  });
  */